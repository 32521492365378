import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { DocumentType } from 'src/app/shared/models';
import { FileSignature } from 'src/app/shared/models/file-signature';
import { Member, MemberAddress, MemberBeneficiary, MemberBenefit, MemberDrop, MemberFiscal, MemberNotificationOptInsList } from '../models';
import { SetNotificationOptInOptOut } from '../models/member-opt-in';
import { MemberInfo } from '../models/member';
import { PaymentHistoryRequest } from '../models/payment-history-request';
import { PaymentHistoryResponse } from '../models/payment-history-response';

export const loadMember = createAction('[Member Data] Load Member', props<{ id: string }>());
export const loadMemberSuccess = createAction('[Member Data] Load Member Success', props<{ member: MemberInfo, id: string }>());
export const loadMemberFailure = createAction('[Member Data] Load Member Failure', props<{ id: string, error: HttpErrorResponse }>());

export const loadMetadata = createAction('[Member Data] Load Member Metadata', props<{ itemKey: string }>());
export const loadMetadataSuccess = createAction('[Member Data] Load Member Metadata Success', props<{ itemKey: string, metadata: any }>());
export const loadMetadataFailure = createAction('[Member Data] Load Member Metadata Failure', props<{ itemKey: string, error: HttpErrorResponse }>());

export const signAttachment = createAction('[Member Data] Sign Attachment', props<{ itemKey: string, fileKey: string }>());
export const signAttachmentSuccess = createAction('[Member Data] Sign Attachment Success', props<{ itemKey: string, signature: FileSignature }>());
export const signAttachmentFailure = createAction('[Member Data] Sign Attachment Failure', props<{ itemKey: string, fileKey: string, error: HttpErrorResponse }>());

export const loadDrop = createAction('[Member Data] Load DROP', props<{ id: string }>());
export const loadDropSuccess = createAction('[Member Data] Load DROP Success', props<{ drop: MemberDrop }>());
export const loadDropFailure = createAction('[Member Data] Load DROP Failure', props<{ id: string, error: HttpErrorResponse }>());

export const loadBenefit = createAction('[Member Data] Load Benefit', props<{ id: string }>());
export const loadBenefitSuccess = createAction('[Member Data] Load Benefit Success', props<{ benefit: MemberBenefit }>());
export const loadBenefitFailure = createAction('[Member Data] Load Benefit Failure', props<{ id: string, error: HttpErrorResponse }>());

export const loadFiscal = createAction('[Member Data] Load Fiscal', props<{ id: string }>());
export const loadFiscalSuccess = createAction('[Member Data] Load Fiscal Success', props<{ fiscal: MemberFiscal }>());
export const loadFiscalFailure = createAction('[Member Data] Load Fiscal Failure', props<{ id: string, error: HttpErrorResponse }>());

export const loadBeneficiary = createAction('[Member Data] Load Beneficiary', props<{ id: string }>());
export const loadBeneficiarySuccess = createAction('[Member Data] Load Beneficiary Success', props<{ beneficiary: MemberBeneficiary }>());
export const loadBeneficiaryFailure = createAction('[Member Data] Load Beneficiary Failure', props<{ id: string, error: HttpErrorResponse }>());

export const signFile = createAction('[Member Data] Sign File', props<{ fileKey: string, docType: DocumentType }>());
export const signFileSuccess = createAction('[Member Data] Sign File Success', props<{ signature: FileSignature, docType: DocumentType }>());
export const signFileFailure = createAction('[Member Data] Sign File Failure', props<{ fileKey: string, error: HttpErrorResponse }>());

export const loadAddress = createAction('[Member Data] Load Address', props<{ id: string }>());
export const loadAddressSuccess = createAction('[Member Data] Load Address Success', props<{ address: MemberAddress }>());
export const loadAddressFailure = createAction('[Member Data] Load Address Failure', props<{ id: string, error: HttpErrorResponse }>());

export const loadOptInMessage = createAction('[Member Data] Load Opt-In Message', props<{ clientId:string, memberId:string }>());
export const loadOptInMessageSuccess = createAction('[Member Data] Load Opt-In Message Success', props<{ lstMemberNotificationOptIns: MemberNotificationOptInsList }>());
export const loadOptInMessageFailure = createAction('[Member Data] Load Opt-In Message Failure', props<{ clientId:string, memberId:string, error: HttpErrorResponse }>());

export const setOptInMessage = createAction('[Member Data] Set Opt-In Message', props<{ memberId:string, body: SetNotificationOptInOptOut }>());
export const setOptInMessageSuccess = createAction('[Member Data] Set Opt-In Message Success');
export const setOptInMessageFailure = createAction('[Member Data] Set Opt-In Message Failure');

export const generatePaymentInfo = createAction('[Member Data] Generate Payment Info', props<{ paymentHistoryRequest: PaymentHistoryRequest }>());
export const generatePaymentInfoSuccess = createAction('[Member Data] Generate Payment Info Success', props<{ paymentHistoryResponse: PaymentHistoryResponse }>());
export const generatePaymentInfoFailure = createAction('[Member Data] Generate Payment Info Failure');
