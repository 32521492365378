import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  faFileDownload,
  faFilePdf,
  faHeadset,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'

import { Store } from '@ngrx/store'
import { log } from 'console'
import { combineLatest, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { MemberDataActions, MemberSelectors } from 'src/app/member'
import { MetadataSelectors } from 'src/app/metadata'
import { PagesModels } from 'src/app/pages/index'
import { PagesSelectors } from 'src/app/pages/state'
import { LoggingService } from 'src/app/shared'
import { SignatoryService } from 'src/app/shared/services/signatory-service'

@Component({
  selector: 'psrs-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>()
  faFileDownload: IconDefinition = faFileDownload
  faHeadset: IconDefinition = faHeadset
  faFilePdf: IconDefinition = faFilePdf
  attachmentList = []
  listLabel: string = '';
  rowId = ''
  page: PagesModels.PageDefinition

  isPdfModalOpen: boolean = false
  modalLabel: string
  selectedPdfUrl: string

  closePdfModal () {
    this.isPdfModalOpen = false
  }

  downloadPdf () {
    this.loggingService.logEvent('User Downloaded Pdf')
    window.open(this.selectedPdfUrl, '_blank')
  }

  constructor (
    private store: Store,
    private router: Router,
    private signatoryService: SignatoryService,
    private loggingService: LoggingService
  ) {
    this.attachmentList =
      this.router.getCurrentNavigation().extras.state.attachmentList
    this.page = this.router.getCurrentNavigation().extras.state.page
    this.listLabel = this.router.getCurrentNavigation().extras.state.listLabel;
  }

  ngOnInit (): void {
    
  }

  ngOnDestroy (): void {
    this.unsubscribe$.next()
  }

  getPdfFile (attachment: any, isDownload: boolean = false): void {
    let attachmentFile = attachment.fileSnapshot
    this.store.select(MemberSelectors.getSignature).subscribe(signature => {
      if (signature == null) {
        this.store.dispatch(
          MemberDataActions.signAttachment({
            itemKey: this.page.id,
            fileKey: attachmentFile.key
          })
        )
        return
      }
      if (attachmentFile.signature == null) {
        attachmentFile = Object.assign({}, attachmentFile, { signature })
      }

      if (isDownload) {
        const downloadUrl = this.signatoryService.getUrl(attachmentFile)
        if (downloadUrl) {
          this.loggingService.logEvent('User Downloaded Pdf')
          window.open(downloadUrl, '_blank')
        }
        return
      }

      this.modalLabel = attachmentFile.label
      this.selectedPdfUrl = attachmentFile.uri
      this.isPdfModalOpen = true
    })
  }
}
